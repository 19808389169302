import { useStorage } from "@vueuse/core";
import moment from "moment";
import m from "moment-with-locales-es6";
import "moment/dist/locale/fr";
import { watch } from "vue";

import { createI18n as createClientI18n } from "vue-i18n";

import messages from "addeus-common-library/stores/locale";

const DEFAULT_LOCALE = "en";

export function createI18n() {
    const locale = navigator?.language;
    const defaultNavigatorLocale = locale?.split("-")[0];

    const navigatorLocale = useStorage(
        "locale",
        defaultNavigatorLocale || DEFAULT_LOCALE,
    );

    const i18n = createClientI18n({
        globalInjection: true,
        legacy: false,
        locale: navigatorLocale.value,
        messages,
        fallbackLocale: DEFAULT_LOCALE,
        missingWarn: false,
        fallbackWarn: false,
    });

    watch(
        navigatorLocale,
        (value) => {
            moment.locale(value);

            /** @Todo delete when library deleted */
            m.locale(value);
        },
        {
            immediate: true,
        },
    );

    return i18n;
}
