<script setup lang="ts">
import { useRouter } from "vue-router";

const router = useRouter();

void router.push({
    name: "dashboard",
});
</script>

<template>
    <div class="page-content-inner"></div>
</template>
