import { defineAsyncComponent } from "vue";
import { START_LOCATION } from "vue-router";
import { plugin as VueTippy } from "vue-tippy";

import { useUserSession } from "addeus-common-library/stores/userSession";

import type { AddeusAppContext } from "./app";

import { useTitle } from "@vueuse/core";
import t from "addeus-common-library/directives/t";

// Lazy load aditional components
export function registerGlobalComponents({ app }: AddeusAppContext) {
    app.use(VueTippy, {
        component: "Tippy",
        defaultProps: {
            theme: "light",
        },
    });

    app.component(
        // eslint-disable-next-line vue/multi-word-component-names
        "Multiselect",
        defineAsyncComponent({
            loader: () => import("@vueform/multiselect").then((mod) => mod.default),
            delay: 0,
            suspensible: false,
        }),
    );
    app.component(
        // eslint-disable-next-line vue/multi-word-component-names
        "Slider",
        defineAsyncComponent({
            loader: () => import("@vueform/slider").then((mod) => mod.default),
            delay: 0,
            suspensible: false,
        }),
    );
    app.component(
        "VCalendar",
        defineAsyncComponent({
            loader: () => import("v-calendar").then((mod) => mod.Calendar),
            delay: 0,
            suspensible: false,
        }),
    );
    /* app.component(
        "DatePicker",
        defineAsyncComponent({
            loader: () => import("v-calendar").then((mod) => mod.DatePicker),
            delay: 0,
            suspensible: false,
        })
    );*/

    /* app.component(
        "VTelInput",
        defineAsyncComponent({
            loader: () => import("vue-tel-input").then((mod) => mod.VueTelInput),
            delay: 0,
            suspensible: false,
        })
    );*/

    app.directive("t", t);
}

/**
 * Here we are setting up two router navigation guards
 *
 * We can add meta to pages either by declaring them manualy in the
 * routes declaration (see /@src/router.ts)
 * or by adding a <route> tag into .vue files (see /@src/pages/sidebar/dashboards.ts)
 *
 * <route lang="yaml">
 * meta:
 *   requiresAuth: true
 * </route>
 *
 * <script setup lang="ts">
 *  // TS script
 * </script>
 *
 * <template>
 *  // HTML content
 * </template>
 */
export function registerRouterNavigationGuards({ router }: AddeusAppContext) {
    // const { t } = useI18n();
    const title = useTitle("Addeus");

    router.beforeEach(async (to, from) => {
        const userSession = useUserSession();

        await userSession.waitInitialize;
        // sconsole.log(userSession.isLoggedIn.value);

        if (from === START_LOCATION && userSession.isLoggedIn.value) {
            // 1. If the name is not set, it means we are navigating to the first page
            // and we are logged in, so we should check user information from the server
            try {
                if (
                    to.meta.roles !== undefined &&
                    !userSession.hasPermission(to.meta.roles)
                ) {
                    return {
                        // Will follow the redirection set in /@src/pages/auth/index.vue
                        name: "auth",
                        // save the location we were at to come back later
                        query: { redirect: to.fullPath },
                    };
                }
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
                // delete stored token if it fails
                void userSession.logout();

                if (to.meta.requiresAuth === true) {
                    // redirect the user somewhere
                    return {
                        // Will follow the redirection set in /@src/pages/auth/index.vue
                        name: "auth",
                        // save the location we were at to come back later
                        query: { redirect: to.fullPath },
                    };
                }
            }
        } else if (
            (to.meta.requiresAuth !== undefined || to.meta.roles !== undefined) &&
            userSession.isLoggedIn === false
        ) {
            return {
                // Will follow the redirection set in /@src/pages/auth/index.vue
                name: "auth",
                // save the location we were at to come back later
                query: { redirect: to.fullPath },
            };
        }
    });

    router.afterEach((to) => {
        title.value =
            to.meta.hasOwnProperty("title") && typeof to.meta.title === "string"
                ? to.meta.title
                : "Addeus";
    });
}
