import StackdriverErrorReporter from "stackdriver-errors-js";

let errorHandler: StackdriverErrorReporter | null = null;
if (process.env.NODE_ENV !== "development") {
    errorHandler = new StackdriverErrorReporter();
    errorHandler.start({
        key: import.meta.env.VITE_FIREBASE_API_KEY,
        projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    });
}

export function setUser(data) {
    if (errorHandler) {
        errorHandler.setUser(data);
    }
}
